import { useEffect, useRef, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";
//redux
import { useSelector } from "react-redux";
import AddAdmin from "./add-admin";
import { useTranslation } from "react-i18next";
import { IAdmin, IRole } from "src/interfaces/general";
import useAdmins from "src/hooks/useAdmins";
import TablePage, { makeColumns } from "src/components/Common/TablePage";
import ImportCSV from "src/components/Common/ImportCSV";
import { exportToCSV } from "src/helpers/file_helper";
import { getV2AuthUrl, useV2 } from "src/helpers/v2Helper";

const Admins = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { currentUser } = useSelector((state: any) => state.login);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const { admins, loading, success, errorAlert, errorMessage, deleteAdmin, addAdmin, addAdmins } = useAdmins(currentUser?.code || currentUser?.companyCode);
  //const [adminData, setAdminData] = useState<any>();
  const [drawer, setDrawer] = useState<Boolean>(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [role, setRole] = useState<any>();
  const [isAdmin, setIsAdmin] = useState<Boolean>(false);
  const currentAdmin = useRef<IAdmin>();
  const hasPermission = currentUser?.permissions?.admins;
  const use_v2 = useV2();

  const defaultSorted: any = [
    {
      dataField: "siteCode", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      setIsAdmin(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (admins) {
      admins.forEach((admin: any) => {
        admin.firstname = admin.firstName;
        admin.lastname = admin.lastName;
      })
      console.log("admins", admins);
    }
  }, [admins]);

  const onEdit = (admin: any) => {
    if (use_v2) {
      window.location.href = `${getV2AuthUrl()}/account/${admin.id}?returnUrl=${encodeURIComponent(window.location.href)}`;
    } else {
      history.push(`/admins/${admin.id}`);
    }

  };

  const onDelete = (admin: any) => {
    if (use_v2) {
      window.location.href = `${getV2AuthUrl()}/account/${admin.id}?returnUrl=${encodeURIComponent(window.location.href)}`;
    } else {
      currentAdmin.current = admin;
      setShowConfirmAlert(true);
    }
  };

  const onAdd = () => {
    if (use_v2) {
      window.location.href = `${getV2AuthUrl()}/account/add?returnUrl=${encodeURIComponent(window.location.href)}`;
    } else {
      setDrawer(true);
    }
  }

  const confirmDelete = async () => {
    setShowConfirmAlert(false);
    const result = await deleteAdmin(currentAdmin.current?.id || "");
    if (result) {
      setShowSuccessAlert(true);
    }
  };

  const onExport = () => {
    console.log("admins", admins);
    var copy = admins.slice();
    copy.forEach(admin => {
      admin.role = admin.role?.name ?? ""
    })
    exportToCSV(copy, "admins", ["password", "pushTokens", "verifyCode", "verifyCount"]);
    copy = [];
  }

  const onDrawerClose = (value: Boolean) => {
    setDrawer(value);
  };

  const onImport = () => {
    setIsModal(true);
  };

  const onClose = () => {
    setIsModal(false)
  };

  const onAddImportData = (data: any) => {
    addAdmins(
      data.map((item: any) => ({
        companyCode: currentUser?.LOGIN_METHOD === "COMPANY_LOGIN" ? currentUser.code : currentUser.companyCode,
        siteCode: item.sitecode,
        userName: item.username,
        firstName: item.firstname,
        lastName: item.lastname,
        email: item.email,
        phone: item.phone || "none",
        pincode: item.pincode,
        role: { name: item.role },
        password: "Hendy1234"
      }))
    )
    setIsModal(false)
  };



  return (
    <TablePage
      loading={loading}
      metaTitle={t("Admins")}
      data={admins}
      onAdd={onAdd}
      //onImport={onImport}
      onExport={isAdmin ? onExport : undefined}
      hasPermission={hasPermission}
      title={t("Admins")}
      description={`(${admins.length})`}
      columns={makeColumns(onDelete, onEdit, header)}
      pageSize={10}
      totalSize={admins.length}
      defaultSorted={defaultSorted}
      footer={
        <AddAdmin
          open={drawer}
          onDrawerClose={onDrawerClose}
          addAdmin={addAdmin}
          loading={loading}
          success={success}
          errorAlert={errorAlert}
          errorMessage={errorMessage}
        />
      }
      modal={
        <ImportCSV
          open={isModal}
          onClose={onClose}
          onAddImportData={onAddImportData}
          loading={loading}
        />
      }

      // Alerts
      confirmAlert={{
        visible: showConfirmAlert,
        onConfirm: confirmDelete,
        onCancel: () => setShowConfirmAlert(false)
      }}
      successAlert={{
        visible: showSuccessAlert,
        onConfirm: () => setShowSuccessAlert(false)
      }}
    />
  );
};

export default withRouter(Admins);

const getInitials = (admin: any) => {
  const firstName = admin?.firstname || admin?.firstName;
  const lastName = admin?.lastname || admin?.lastName;
  return firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase();
}

const header = [
  {
    dataField: "",
    editable: false,
    text: "Avatar",
    formatter: (_: any, admin: any) => (
      <div className="rounded-circle header-profile-user initials">
        {getInitials(admin)}
      </div>
    ),
  },
  {
    dataField: "firstname",
    text: "Name",
    sort: true,
    formatter: (_: any, admin: any) => (
      <div >
        {(admin?.firstName || admin?.firstname) + " " + (admin?.lastName || admin?.lastname)}
      </div>
    ),
  },
  {
    dataField: "roles[0]",
    text: "Role",
    sort: true,
    formatter: (_: any, admin: any) => {
      const roleName = typeof admin?.role === 'string' 
        ? admin.role 
        : admin?.role?.name || '';
      return (
        <div>
          {roleName.toLowerCase().replace(" ", "")}
        </div>
      );
    },
  },
  {
    dataField: "siteCode",
    text: "Site Code",
    sort: true,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
  },
  {
    dataField: "phone",
    text: "Phone",
    sort: true,
  },
  {
    dataField: "menu",
    editable: false,
    text: "Action",
  }
];
